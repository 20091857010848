.log-reg-page {
    min-height: 100vh;
    @extend .d-flex,
    .align-items-center,
    .justify-content-center;
}

.log-reg-wrap {
    @extend .mx-auto,
    .px-3;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: 450px;
    width: 100%;
    font-size: 14px;
    @include media-breakpoint-down(sm) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    h4 {
        @extend .font-weight-medium,
        .text-center,
        .my-4;
    }
    .log-reg-inputs-wrap {
        @extend .mt-5,
        .overflow-hidden;
        box-shadow: 0 0 38px rgb(225, 234, 252);
        border-radius: 2px;
        overflow: hidden;
        .form-group {
            @extend .mb-0;
            &+.form-group {
                border-top: 1px #dce2f0 solid;
            }
        }
        .form-control {
            @extend .rounded-0;
            border-color: #FFF;
            color: #686868;
            font-size: 14px;
            height: 3.75rem;
            &:focus {
                box-shadow: none;
                background-color: white;
            }
        }
    }
    .btn-log-reg {
        @extend .btn-block,
        .mt-3,
        .p-2,
        .font-weight-semibold;
        border-radius: 2px;
        font-size: 14px;
        height: 3rem;
        line-height: calc(3rem - 1rem);
    }
    .custom-control {
        @extend .d-flex,
        .align-items-center;
        label {
            line-height: 1.8;
        }
    }
    .custom-control-label:before {
        border-radius: 2px;
        background-color: transparent;
    }
    p {
        margin: 2rem 0 0;
    }
}