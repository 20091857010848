.react-datepicker-wrapper {
  width: 100%;
}

.table-wrapper {
  position: relative;
  overflow: auto;
  border: 0px;
  width: 100%;
  &::-webkit-scrollbar-track {
    width: 8px;
    background-color: #edf0f5;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    height: 8px;
    background-color: #a7a8ac;
  }
  &.loading {
    opacity: 0.5;
    & > i {
      position: relative;
      top: 300px;
      left: 50%;
    }
  }
}

.DataTable {
  .sortable {
    cursor: pointer;
    span {
      &::before {
        content: '\2191';
        opacity: 0.4;
      }

      &::after {
        content: '\2193';
        opacity: 0.4;
      }
    }
    .asc {
      &::before {
        opacity: 1;
      }
    }

    .desc {
      &::after {
        opacity: 1;
      }
    }
  }
}

.m-t-l {
  margin-top: 40px;
}
