$theme-colors: ( 'primary':#33b1ba, 'secondary': #666666, 'info': #1ec9b7, 'warning': #ffb822, 'success': #8cc63f, 'danger': #f62e48, );
@import '~bootstrap/scss/bootstrap.scss';
@import 'assets/fontawesome/fontawesome';
@import 'assets/fontawesome/solid';
@import 'assets/styles/fonts';
@import 'assets/styles/overrides';
@import 'assets/styles/global';
@import 'assets/styles/custom-forms';
@import 'assets/styles/footer';
@import 'assets/styles/header';
@import 'assets/styles/login-register';