.#{$prefix} {
    &footer {
        @extend .py-4;
        .container-fluid {
            @extend .d-md-flex,
            .justify-content-between;
        }
        p,
        a {
            color: #727373;
        }
        &-links {
            @extend .order-md-1,
            .my-3;
            a {
                @include media-breakpoint-up(md) {
                    &+a {
                        margin-left: 1rem;
                    }
                }
                @include media-breakpoint-down(sm) {
                    margin: 0 .5rem;
                }
            }
        }
        &-coyprights {
            p {
                @extend .my-3;
            }
        }
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
}