.#{$prefix}header-wrap {
    .#{$prefix} {
        &header-top {
            @extend .bg-primary;
            .container-fluid {
                @extend .d-md-flex,
                .justify-content-between,
                .align-items-center;
            }
            p,
            p a {
                @extend .text-white;
            }
            &-left {
                @extend .d-md-block;
               // p {
               //     @extend .my-2;
              //  }
            }
            &-right {
                @extend .d-flex,
                .justify-content-between,
                .align-items-center;
                p {
                    @extend .my-2;
                }
                &-nav {
                    @extend .ml-3;
                    &>ul {
                        @extend .m-0,
                        .p-0,
                        .list-unstyled,
                        .d-flex;
                        &>li {
                            @extend .border-left;
                            border-color: #994297 !important;
                            .user-image {
                                @extend .bg-white,
                                .rounded-circle,
                                .overflow-hidden,
                                .position-relative;
                                width: 30px;
                                height: 30px;
                                img {
                                    @extend .position-absolute,
                                    .w-auto,
                                    .h-auto;
                                    min-width: 100%;
                                    min-height: 100%;
                                    object-fit: cover;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                            &>div>a,
                            .dropdown>button {
                                @extend .d-flex,
                                .justify-content-center,
                                .align-items-center,
                                .bg-transparent,
                                .border-0,
                                .text-white,
                                .position-relative;
                                min-height: 3.5rem;
                                min-width: 4.5rem;
                                transition: all .5s;
                                &:hover,
                                &:focus {
                                    background-color: darken($theme-color, 2%) !important;
                                    outline: none;
                                }
                            }
                            .alerts-link {
                                .badge {
                                    @extend .position-absolute,
                                    .rounded-circle;
                                    right: .8rem;
                                    top: .8rem;
                                    font-weight: normal;
                                    font-size: 11px;
                                }
                            }
                            .bell-icon {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
        &header-bottom {
            @extend .bg-white,
            .position-relative;
            border-bottom: #e8eaf2 1px solid;
            .container-fluid {
                @extend .d-flex,
                .justify-content-between,
                .align-items-center;
            }
            &-logo {
                @extend .my-2;
                max-width: 175px;
            }
            &-menu-wrap {
                @include media-breakpoint-down(lg) {
                    order: 1;
                    margin: 0 1rem;
                }
            }
            &-search-icon {
                @include media-breakpoint-down(lg) {
                    margin-left: auto;
                }
            }
            &-search-bar {
                @extend .position-absolute,
                .w-100,
                .bg-white;
                margin-top: 1px;
                top: 100%;
                left: 0px;
                opacity: 0;
                visibility: hidden;
                transition: all .5s;
                &.show {
                    opacity: 1;
                    visibility: visible;
                }
                .form-control,
                .btn {
                    @extend .rounded-0;
                }
                .btn {
                    @extend .position-absolute;
                    right: 0px;
                    top: 0px;
                }
            }
            &-menu-mob-icon {
                @extend .d-block,
                .d-xl-none;
            }
            .mob-nav-bg {
                background-color: rgba(0, 0, 0, .3);
                opacity: 0;
                visibility: hidden;
                position: fixed;
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                opacity: 0;
                z-index: 1;
                transition: all .5s;
            }
            @include media-breakpoint-down(lg) {
                .mob-nav-bg {
                    /* background-color: rgba(0, 0, 0, .3);
                    opacity: 0;
                    visibility: hidden;
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    left: 0px;
                    top: 0px;
                    opacity: 0;
                    z-index: 1;
                    transition: all .5s; */
                    &.show {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &-menu {
                &-colser {
                    @extend .rounded-0,
                    .btn-block,
                    .d-block,
                    .d-xl-none;
                }
                ul {
                    @extend .m-0,
                    .p-0,
                    .list-unstyled;
                    li {
                        a {
                            @extend .d-block;
                            color: #686868;
                            transition: all .5s;
                        }
                    }
                }
                @include media-breakpoint-down(lg) {
                    min-height: 100vh;
                    position: fixed;
                    top: 0;
                    right: -110%;
                    width: 280px;
                    background-color: #FFF;
                    bottom: 0;
                    overflow-y: auto;
                    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
                    transition: all .5s;
                    z-index: 2;
                    &.show {
                        right: 0px;
                    }
                    &>ul {
                        &>li {
                            width: 100%;
                            &+li {
                                border-top: #f0f0f0 1px solid;
                            }
                        }
                    }
                }
                &>ul {
                    @extend .d-xl-flex;
                    &>li {
                        @extend .position-relative;
                        &>a {
                            @extend .p-3,
                            .position-relative;
                            @include media-breakpoint-up(xl) {
                                line-height: 2.75rem;
                            }
                        }
                        &.active a,
                        &:hover>a,
                        &>a:hover {
                            color: $theme-color;
                        }
                        &.active {
                            @extend .font-weight-bold;
                            color: $theme-color;
                            &:before {
                                @extend .d-block,
                                .position-absolute;
                                left: 50%;
                                bottom: 0;
                                content: '';
                                width: 0px;
                                height: 0px;
                                border-width: 7px;
                                border-style: solid;
                                border-color: transparent transparent $theme-color transparent;
                                transform: translateX(-50%);
                            }
                            @include media-breakpoint-down(lg) {
                                &:before {
                                    display: none !important;
                                }
                            }
                        }
                        &.has-submenu {
                            &>a {
                                @extend .pr-4;
                                &:before {
                                    @extend .d-block,
                                    .position-absolute;
                                    top: 50%;
                                    right: .5rem;
                                    content: '';
                                    width: 0px;
                                    height: 0px;
                                    border-width: 5px;
                                    border-style: solid;
                                    border-color: #686868 transparent transparent transparent;
                                    transform: translateY(-50%);
                                    margin-top: 3px;
                                }
                            }
                            &:hover>a,
                            &>a:hover {
                                &:before {
                                    border-color: $theme-color transparent transparent transparent;
                                }
                            }
                            ul {
                                opacity: 0;
                                visibility: hidden;
                                @include media-breakpoint-up(xl) {
                                    //display: none !important;
                                    //width: 100%;
                                    min-width: 190px;
                                    background-color: #FFF;
                                    position: absolute;
                                    top: 100%;
                                    left: 50%;
                                    margin-top: .5rem !important;
                                    transform: translateX(-50%);
                                    box-shadow: 0px 4px 5px rgba(0, 0, 0, .03);
                                    z-index: 100;
                                    white-space: nowrap;
                                    transition: all .2s;
                                }
                                li {
                                    a {
                                        padding: .5rem 1rem;
                                    }
                                    &:hover a,
                                    & a:hover {
                                        //background-color: #fafafa;
                                        background-color: rgba($theme-color, .05);
                                        color: $theme-color;
                                    }
                                }
                            }
                            @include media-breakpoint-up(xl) {
                                &:hover {
                                    &>ul {
                                        opacity: 1;
                                        visibility: visible;
                                        margin-top: 0 !important;
                                        display: block !important;
                                    }
                                }
                            }
                            @media (min-width: 768px) {
  
                            }
                        }
                    }
                }
            }
        }
    }
}

.#{$prefix} {
    &breadcrumb {
        @extend .order-md-1;
        ul {
            @extend .list-unstyled,
            .my-0,
            .p-0,
            .d-flex,
            .flex-wrap;
            li {
                @extend .mr-3;
                &:last-child {
                    @extend .mr-0;
                }
                &.dashboard-link a {
                    color: #717171;
                }
                &:not(.dashboard-link) {
                    a,
                    strong {
                        color: #424242;
                    }
                  
                }


                
              
            }
           
        }
    }
    &sub-header {
        @extend .d-md-flex,
        .align-items-center,
        .justify-content-between,
        .py-2;
        min-height: 3.75rem;
        h5 {
            @extend .font-weight-semibold,
            .my-2;
        }
       
       
    }

    &breadcrumb {
        @include media-breakpoint-up(xs) {
            display: none;
         }
         @include media-breakpoint-up(sm) {
             display: block;
          }
         
    }
    &header-top-left{
     
        @include media-breakpoint-up(xs) {
            display: block !important;
            font-size: 12px;
            border-bottom: solid 1px #994297;
          
            p{
                margin-bottom: 1rem;
                margin-top: 0rem!important;
              
            }

         }
         @include media-breakpoint-up(sm) {
             
             display: block !important;
             font-size: 14px;
             border-bottom: none;
            
             p{
                margin-bottom: .5rem;
                margin-top: .5rem!important;
            }
          }
         
    }
}