$prefix: bl-;
$theme-color: #33b1ba;
body {
    font-family: 'Poppins', sans-serif;
    color: #686868;
    font-size: 14px;
    //color: #575757;
    background-color: #f5f6fa;
}

* :focus {
    outline: -webkit-focus-ring-color none;
    outline: none;
}

a:visited,
a:focus,
a:active,
a:hover {
    outline: none !important;
    text-decoration: none;
}

::-webkit-input-placeholder {
    opacity: 1;
}

:-moz-placeholder {
    /* Firefox 18- */
    opacity: 1;
}

::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 1;
}

:-ms-input-placeholder {
    opacity: 1;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    opacity: 0.5;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
    opacity: 0.5;
}


/* FF 4-18 */

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    opacity: 0.5;
}

input:focus,
select:focus {
    outline: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
select:-webkit-autofill:hover,
input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    box-shadow: 0 0 0px 1000px white inset;
    background-color: #fff !important;
}

img {
    max-width: 100%;
    height: auto;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    color: #575757;
}

.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    display: block;
}

.text-underline {
    text-decoration: underline !important;
}

b,
strong,
.font-weight-bold {
    font-weight: normal;
    @extend .font-weight-semibold;
}


/* .btn {
    border: none;
    border-radius: 2px;
    padding: 0 1rem;
    line-height: 3rem;
    font-weight: 600;
    font-size: 0.875rem;
}

.btn.focus,
.btn:focus {
    box-shadow: none !important;
} */


/* .btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle,
.btn-secondary:hover {
    color: #fff;
    background-color: #021541;
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .5);
    color: #fff;
} */

.mw-1400 {
    max-width: 1400px;
}

.form-control {
    /* height: calc(1.5em + 1rem + 2px); */
    height: calc(1.5em + 1rem + 5px);
    padding: .4rem .75rem;
    &:focus {
        box-shadow: none;
    }
    &-icon {
        @extend .position-absolute;
        font-size: 1.5rem;
        right: .5rem;
        top: .5rem;
        color: #a9a8a8;
        pointer-events: none;
    }
}

.btn {
    //padding: .5rem .75rem;
    /* padding: .6rem .75rem;
    font-size: 14px; */
    padding: .5rem .75rem;
    &:not(.dropdown-toggle) {
        @extend .font-weight-semibold;
        font-size: 15px;
    }
}

.bootstrap-select {
    .btn {
        font-size: 14px;
        height: calc(1.5em + 1rem + 5px);
        padding: .4rem .75rem;
    }
}

.btn-success {
    color: #FFF;
}

.btn-light-blue {
    background-color: #eeeffc;
    color: #5867dd;
    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:focus:hover {
        background-color: darken(#eeeffc, 5%);
    }
}

.form-control {
    font-size: 14px;
}

.text-blue {
    color: #374afb;
}

.bootstrap-select {
    &>select {
        left: 0px;
    }
    .bootstrap-selectpicker {
        &+.btn {
            @extend .bg-transparent,
            .border;
            border-color: #ced4da !important;
            &:focus {
                @extend .border-primary;
                outline: none !important;
                box-shadow: none !important;
            }
        }
    }
}

.custom-control-label:after,
.custom-control-label:before {
    top: .1rem;
}

.minw-242px {
    min-width: 242px;
}

.mw-300px {
    min-width: 300px;
}

.mw-550 {
    max-width: 550px;
}

.btn-light {
    background-color: #eeeeee;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    bottom: inherit;
    top: 50%;
    transform: translateY(-50%);
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    @include media-breakpoint-down(sm) {
        justify-content: center;
        margin-top: .5rem;
    }
}

.icon-blue {
    color: #687ddb;
}

.#{$prefix} {
    &badge-number {
        @extend .badge,
        .badge-light,
        .badge-pill,
        .position-relative,
        .my-2,
        .ml-2;
        margin-right: 20px;
        color: #757575;
        padding: .4rem .5rem;
        font-size: 14px;
        font-weight: normal;
        a {
            @extend .position-absolute,
            .text-danger;
            right: -16px;
            top: 0px;
            &.check {
                @extend .text-success;
            }
        }
    }
    &top-right-btns {
        .btn {
            @include media-breakpoint-up(sm) {
                margin-right: .5rem;
            }
            @include media-breakpoint-down(xs) {
                display: block;
                margin: .5rem 0;
            }
        }
    }
    &content-box {
        @extend .bg-white,
        .rounded,
        .p-4;
        min-height: calc(100vh - 18.25rem);
        box-shadow: 14px 0 21px rgba(#adb6d9, .17);
        &-nav-bar {
            @extend .bg-primary,
            .rounded;
            border: #b9c6e0 1px solid;
            ul {
                @extend .list-unstyled,
                .mb-0,
                .pb-0,
                .d-flex,
                .flex-wrap;
                @include media-breakpoint-down(sm) {
                    justify-content: left;
                }
                li {
                    @extend .font-weight-medium,
                    .position-relative;
                    font-size: 16px;
                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                    }
                    a {
                        @extend .d-block;
                        padding: 1.2rem 1rem;
                        color: lighten($theme-color, 50%);
                        &:hover {
                            @extend .text-white;
                        }
                        @include media-breakpoint-down(sm) {
                            padding: .8rem .5rem;
                        }
                    }
                    &.active {
                        @extend .font-weight-bold;
                        &:before {
                            @extend .d-block,
                            .position-absolute;
                            left: 50%;
                            bottom: 0;
                            content: "";
                            width: 0;
                            height: 0;
                            border-width: 7px;
                            border-style: solid;
                            border-color: transparent transparent #FFF;
                            transform: translatex(-50%);
                        }
                        a {
                            @extend .text-white;
                        }
                    }
                }
            }
        }
    }
    &upload-steps-bar {
        @extend .mx-auto,
        .my-3,
        .px-2;
        //padding: .6rem;
        border-radius: 3rem;
        background-color: #eeeeee;
        max-width: 650px;
        ul {
            @extend .d-flex,
            .justify-content-around,
            .flex-wrap,
            .m-0,
            .p-0,
            .list-unstyled;
            li {
                @extend .d-flex,
                .align-items-center,
                .m-2;
                color: #151617;
                .step-circle {
                    @extend .rounded-circle,
                    .mr-2,
                    .text-center,
                    .text-white;
                    line-height: 1.5rem;
                    background-color: #bfbfbf;
                    width: 1.5rem;
                    height: 1.5rem;
                }
                &.current {
                    .step-circle {
                        @extend .bg-primary;
                    }
                }
                &.completed {
                    .step-circle {
                        position: relative;
                        background-color: #41ce75;
                        overflow: hidden;
                        &:before,
                        &:after {
                            @extend .d-block,
                            .position-absolute;
                            content: '';
                        }
                        &:before {
                            background-color: #41ce75;
                            width: 1.5rem;
                            height: 1.5rem;
                            z-index: 2;
                            left: 0px;
                            top: 0px;
                        }
                        &:after {
                            width: 10px;
                            height: 6px;
                            border-left: #FFF 2px solid;
                            border-bottom: #FFF 2px solid;
                            left: 50%;
                            top: 45%;
                            transform: translate(-50%, -50%) rotate(-45deg);
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }
    &file-uploader {
        @extend .text-center,
        .px-4,
        .py-5,
        .h-25;
        p {
            @extend .mx-auto;
            max-width: 540px;
            color: #a9a8a8;
        }
    }
    &title-bar {
        @extend .bg-primary,
        .rounded,
        .mb-3;
        padding: 1.3rem 1.375rem;
        border: #b9c6e0 1px solid;
        h6 {
            @extend .text-white,
            .m-0;
            font-size: 16px;
        }
    }
    &info-bar {
        @extend .d-md-flex,
        .justify-content-center,
        .flex-wrap;
        span {
            @extend .font-weight-semibold,
            .my-2;
            width: 100%;
            font-size: 14px;
            @include media-breakpoint-up(md) {
                max-width: 216px;
                min-width: 216px;
            }
        }
        span {
            @extend .text-center,
            .d-block,
            .rounded;
            padding: .6rem .75rem;
            margin-right: .5rem;
            /* &:last-child {
                margin-right: 0px;
            } */
            &.border-primary {
                @extend .text-primary;
            }
            &.border-danger {
                @extend .text-danger;
            }
            &.border-success {
                @extend .text-success;
            }
            &.bg-warning {
                @extend .text-dark;
            }
            &.bg-dark {
                @extend .text-white;
            }
            /* @include media-breakpoint-up(md) {
                &+span {
                    margin-left: .5rem;
                }
            } */
        }
    }
    &btns-bar {
        @extend .text-center;
        .btn {
            @extend .m-1,
            .font-weight-semibold;
            font-size: 12px;
            padding: .7rem .75rem;
            width: 140px;
            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }
    }
    &table {
        @extend .w-100;
        &:not(.dataTable),
        &.no-footer {
            border-bottom: #d1d8f5 1px solid;
        }
        &.no-footer {
            @extend .mb-3;
        }
        th,
        td {
            @extend .px-4;
        }
        &:not(.text-left) {
            th,
            td {
                &:not(:first-child) {
                    @extend .text-center;
                    &.text-left {
                        @extend .text-left;
                    }
                    &.text-center {
                        @extend .text-center;
                    }
                }
            }
        }
        thead {
            th {
                @extend .py-4;
                font-size: 14px;
                color: #686868;
                border-bottom: #d1d8f5 1px solid;
            }
        }
        tbody {
            td {
                font-size: 12px;
                padding-top: .75rem;
                padding-bottom: .75rem;
                &+td {
                    border-left: #d1d8f5 1px solid;
                }
            }
        }
        tfoot {
            th {
                padding-top: .75rem;
                padding-bottom: .75rem;
                &+th {
                    border-left: #d1d8f5 1px solid;
                }
            }
        }
        &-groups-contacts {
            th,
            td {
                @extend .px-2;
            }
            tbody {
                tr:hover {
                    box-shadow: 0 0 0 1px #d1d8f5 inset;
                }
                td {
                    font-size: 14px;
                    border-bottom: #d1d8f5 1px solid;
                    &+td {
                        border-left: 0px;
                    }
                }
            }
        }
    }
    &preview-table-bottom-bar {
        @extend .d-md-flex,
        .justify-content-between,
        .align-items-center,
        .w-100;
        .btn-add-more {
            @extend .font-weight-semibold,
            .my-2;
            width: 100%;
            font-size: 14px;
            @include media-breakpoint-up(md) {
                max-width: 216px;
                min-width: 216px;
            }
        }
        /* .btn-add-more,
        span {
            @extend .font-weight-semibold,
            .my-2;
            width: 100%;
            font-size: 14px;
            @include media-breakpoint-up(md) {
                max-width: 216px;
                min-width: 216px;
            }
        } */
        .btn-add-more {
            @extend .border;
            background-color: #eee;
            &:hover {
                background-color: darken(#eee, 5%);
            }
        }
        /* span {
            @extend .text-center,
            .d-block,
            .rounded;
            padding: .5rem .75rem;
            &.border-primary {
                @extend .text-primary;
            }
            &.bg-warning {
                @extend .text-dark;
            }
            &.bg-dark {
                @extend .text-white;
            }
            @include media-breakpoint-up(md) {
                &+span {
                    margin-left: .5rem;
                }
            }
        } */
    }
    &file-sms-home-screen {
        @extend .text-center;
        h5 {
            @extend .font-weight-bold;
        }
        p {
            @extend .mx-auto;
            max-width: 750px;
            color: #a9a8a8;
        }
        &-tiles {
            a {
                @extend .bg-white,
                .border,
                .d-inline-flex,
                .justify-content-center,
                .align-items-center,
                .flex-column,
                .p-2;
                vertical-align: top;
                width: 210px;
                height: 210px;
                border-radius: 20px;
                margin: 5px;
                color: #898989;
                transition: all .5s;
                box-shadow: 0 0 60px rgba(0, 0, 0, .08);
                i {
                    @extend .w-100,
                    .fa-4x,
                    .mb-3,
                    .text-success;
                }
                &:hover {
                    @extend .bg-primary,
                    .text-white;
                    border-color: #e66ae4 !important;
                    i {
                        @extend .text-white;
                    }
                }
            }
        }
    }
    &groups-contacts-sub-header {
        @include media-breakpoint-up(lg) {
            padding-left: 360px;
        }
    }
    &sidebar {
        &-groups-contacts {
            .row {
                margin-left: -3px;
                margin-right: -3px;
                &>div {
                    padding-left: 3px;
                    padding-right: 3px;
                }
            }
            .btn,
            .form-control,
            .dropdown-menu {
                font-size: 12px;
            }
            .btn-outline-light {
                //border-width: 2px;
                border-color: #ebedf2;
                box-shadow: 0 0 0 1px #ebedf2 inset;
            }
            .top-btns {}
            @include media-breakpoint-up(md) {
                width: 330px;
                margin-right: 30px;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 1rem;
            }
        }
        &-address-book {
            @include media-breakpoint-down(sm) {
                margin-bottom: 1rem;
            }
            h6 {
                @extend .font-weight-bold,
                .mb-3,
                .d-flex,
                .align-items-center,
                .justify-content-between;
                i {
                    font-size: 1.5rem;
                }
            }
            ul {
                @extend .list-unstyled,
                .m-0,
                .p-0;
                li {
                    &+li {
                        border-top: #d9d9d9 1px solid;
                    }
                    a {
                        @extend .d-block,
                        .py-2;
                        color: #a9a8a8;
                        i {
                            @extend .mr-2;
                        }
                    }
                    &.active {
                        @extend .font-weight-bold;
                    }
                }
            }
        }
    }
    &content-area-groups-contacts {
        @include media-breakpoint-up(md) {
            width: calc(100% - 360px);
        }
    }
    &accordion {
        @extend .position-relative;
        &+.bl-accordion {
            @extend .mt-3;
        }
        &-head {
            @extend .rounded,
            .position-relative;
            background-color: #f6f6f6;
            border: #eaeef6 1px solid;
            .step-count {
                @extend .rounded-circle,
                .d-flex,
                .align-items-center,
                .justify-content-center,
                .text-white,
                .font-weight-bold,
                .position-absolute;
                width: 2.25rem;
                height: 2.25rem;
                background-color: #d7d7d7;
                font-size: 16px;
                right: 1.5rem;
                top: 50%;
                transform: translateY(-50%);
            }
            a {
                @extend .font-weight-medium,
                .d-block;
                padding: 1.2rem 1.5rem;
                font-size: 16px;
                &.collapsed {
                    color: #bebebe;
                }
            }
            &.completed a,
            a:not(.collapsed) {
                color: #5a5a5a;
            }
            &.completed,
            &.active,
            a:not(.collapsed) {
                .step-count {
                    background-color: #8a2489;
                }
            }
        }
        &-nav {
            @extend .bl-btns-bar,
            .mt-4;
            a {}
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: -1.1rem;
                right: 5rem;
            }
        }
    }
    &purchase-sms-container {
        @extend .position-relative;
        margin: 5rem 0;
        &>h5,
        &>p {
            @extend .text-center;
        }
        &>h5 {
            @extend .text-dark,
            .font-weight-bold;
        }
        &>p {
            @extend .mx-auto;
            max-width: 750px;
            color: #a9a8a8;
        }
        .selected-payment-method {
            @extend .my-5,
            .text-center,
            .d-flex,
            .align-items-center,
            .justify-content-center,
            .flex-column,
            .bg-white,
            .mx-auto;
            width: 210px;
            height: 185px;
            box-shadow: 0 0 60px rgba(0, 0, 0, .08);
            border-radius: 1.5rem;
            border: #dbdbdb 1px solid;
            /* i {
                @extend .text-success,
                .d-block,
                .my-4,
                .w-100;
                font-size: 3rem;
            } */
            i {
                @extend .text-success,
                .d-block;
                margin: 1.75rem 0;
                font-size: 3rem;
            }
            @include media-breakpoint-up(lg) {
                position: absolute;
                right: 0;
                top: -70px;
            }
        }
        .amount-info {
            @extend .text-primary,
            .font-weight-normal;
            font-size: 20px;
            margin: 2.5rem 0;
            big {
                @extend .font-weight-bold;
                font-size: 24px;
            }
        }
    }
    &purchase-sms-pkgs {
        @extend .text-center;
    }
    &purchase-sms-pkg-box {
        @extend .position-relative,
        .my-3,
        .text-center,
        .d-inline-flex;
        margin-left: .4rem;
        margin-right: .4rem;
        box-shadow: 0 0 60px rgba(0, 0, 0, .08);
        border-radius: 1.5rem;
        input {
            @extend .position-absolute;
            visibility: hidden;
            opacity: 0;
            left: 0;
            top: 0;
        }
        label {
            @extend .bg-white,
            .overflow-hidden,
            .d-block,
            .m-0;
            width: 210px;
            border: #dbdbdb 1px solid;
            border-radius: 1.5rem;
            .box-head {
                @extend .bg-primary,
                .m-0,
                .font-weight-bold,
                .text-white;
                line-height: 40px;
            }
            .box-icon {
                @extend .text-success,
                .d-block;
                line-height: 0;
                margin: 1.75rem 0;
                font-size: 3rem;
                .bl-icon-check {
                    @extend .d-none;
                }
            }
            h5,
            h6 {
                font-size: 1rem;
            }
            h5 {
                @extend .text-primary,
                .font-weight-bold;
            }
            h6 {
                @extend .m-0;
                border-top: #dbdbdb 1px solid;
                line-height: 35px;
                color: #898989;
            }
        }
        input:checked+label {
            @extend .border-primary;
            box-shadow: 0 0 0 1px $theme-color;
            /* border-width: 2px; */
            .box-icon {
                .bl-icon-pkg {
                    @extend .d-none;
                }
                .bl-icon-check {
                    @extend .d-block;
                }
            }
        }
    }
    &payment-type-boxes {
        @extend .text-center;
    }
    &payment-type-box {
        @extend .d-inline-block;
        width: 210px;
        vertical-align: top;
        margin: 1rem 3px;
        &-check {
            @extend .position-relative,
            .d-inline-block;
            input {
                @extend .position-absolute;
                left: 0px;
                top: 0px;
                opacity: 0;
                visibility: hidden;
            }
            label {
                @extend .d-flex,
                .justify-content-center,
                .align-items-center,
                .flex-column,
                .m-0;
                box-shadow: 0 0 60px rgba(0, 0, 0, .08);
                border-radius: 1.5rem;
                width: 210px;
                height: 185px;
                border: #dbdbdb 1px solid;
                i {
                    @extend .text-success,
                    .d-block,
                    .my-4,
                    .w-100;
                    font-size: 3rem;
                }
                span {
                    //@extend .my-2;
                }
            }
            input:checked+label {
                @extend .bg-primary;
                i,
                span {
                    @extend .text-white;
                }
            }
        }
        p {
            font-size: 12px;
            margin: 1rem 0;
            padding: 0 1rem;
        }
    }
    &purchase-sms-payment-info {
        .form-group {
            @extend .d-sm-flex,
            .mt-4;
            font-size: 16px;
            border-bottom: #d1d8f5 1px solid;
            label,
            span {
                padding: .5rem;
            }
            label {
                color: #000;
                margin: 0px;
                max-width: 280px;
                width: 50%;
            }
            span {
                color: #757575;
            }
        }
    }
    &success-container {
        h5 {
            @extend .font-weight-bold,
            .mt-5;
        }
        p,
        dt,
        dd {
            font-size: 12px;
        }
        dl {
            @extend .d-flex,
            .flex-wrap;
        }
        dt,
        dd {
            width: 50%;
            padding: 0 .4rem;
        }
        dt {
            @extend .font-weight-bold,
            .text-right;
        }
    }
    &profile-top-bar {
        // background: url('../images/profile-pattern.jpg') repeat;
        padding: 2rem;
        border-radius: 1rem;
        min-height: 165px;
        @extend .d-lg-flex,
        .align-items-center,
        .flex-wrap;
        h5 {
            width: 100%;
            color: #000000;
            margin: .5rem 0;
        }
        @include media-breakpoint-down(md) {
            min-height: 220px;
            h5 {
                text-align: center;
            }
        }
    }
    &profile-pic-wrap {
        @extend .position-relative,
        .mx-auto;
        width: 150px;
        height: 150px;
        margin-bottom: -100px;
    }
    &profile-pic-wrap-reverse {
        @extend .position-relative,
        .mx-auto;
        width: 200px;
        height: 200px;
        margin-bottom: -150px;
    }
    &profile-pic {
        border: #FFF .5rem solid;
        overflow: hidden;
        border-radius: 50%;
        height: 100%;
        background-color: #efefef
    }
    &profile-pic-edit {
        position: absolute;
        right: 15px;
        top: 10%;
        width: 40px;
        height: 40px;
        input[type="file"] {
            opacity: 0;
            visibility: hidden;
            width: 0;
            height: 0;
            @extend .position-absolute;
        }
        label {
            @extend .rounded-circle,
            .overflow-hidden,
            .d-flex,
            .align-items-center,
            .justify-content-center,
            .m-0,
            .position-absolute;
            width: 40px;
            height: 40px;
            background: #000000;
            font-size: 1.4rem;
            color: #FFF;
            cursor: pointer;
        }
    }
    &profile-bio {
        @extend .text-center;
        margin: 2rem 0;
        h2 {
            @extend .font-weight-bold;
            font-size: 2rem;
            color: #000;
        }
        p {
            font-size: 16px;
            @extend .text-primary;
        }
    }
    &profile-info-wrap {
        .form-group {
            @extend .d-md-flex,
            .align-items-center,
            .mt-2;
            height: calc(100% - 1.5rem);
            border-bottom: #d1d8f5 1px solid;
            label,
            span {
                @extend .m-0,
                .p-2;
                font-size: 16px;
            }
            label {
                color: #000000;
                min-width: 270px;
            }
            .btn {
                min-width: 140px;
            }
            ul {
                list-style: none;
            }
            @include media-breakpoint-down(sm) {
                label,
                span {
                    width: 100%;
                    min-width: inherit;
                    display: block;
                }
            }
        }
    }
}

.list-none {
    list-style: none;
}

.list-none li a {
    color: black;
}

.empty-user {
    color: white;
    margin: 25%;
}

.action-btns {
    &-groups-contacts {
        /* .btn {
            font-size: 20px;
        } */
    }
}

.badge-total-cost {
    @extend .font-weight-semibold,
    .text-white,
    .btn,
    .w-100,
    .text-left;
    background: #000000;
}

.dataTables_scrollFoot {
    margin-bottom: 1rem;
    border-bottom: #d1d8f5 1px solid !important;
}

.dataTables_scrollBody {
    //border-bottom: #d1d8f5 1px solid;
    &::-webkit-scrollbar {
        width: 8px;
        background-color: #edf0f5;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 16px;
        height: 8px;
        background-color: #a7a8ac;
    }
    .dataTable {
        &>thead {
            th {
                @extend .py-0;
            }
        }
    }
}

.alert-sender-names {
    @extend .font-weight-bold;
    border-style: dashed;
    border-color: #fe2020;
    background: transparent;
    color: #fe2020;
}

hr {
    border-top: 1px dashed #818181;
}

.bl-table-groups-contacts-wrap {
    &>.dataTables_wrapper {
        &>.row:first-child {
            display: none;
        }
    }
}

.box-shadow {
    box-shadow: 3px 7px 16px 0 #00000026;
}

.manage-contact-child-table {
    line-height: 0% !important;
}

.manage-contact-child-table thead th {
    border-bottom: 1px solid #d1d8f5;
}

.manage-contact-child-table tbody {
    // border-bottom: 1px solid black;
}

.manage-contact-child-table tbody tr td {
    border-left: 1px solid #d1d8f5;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: none;
}

.incomingsmschat {
    border: 1px solid #d1d8f5;
    padding: 6%;
    border-radius: 1rem;
    margin-left: 6%;
    margin-right: 3%;
    .chat-pic-color-online {
        background-color: #61ab00;
    }
    .chat-pic-color-offline {
        background-color: #e2dddd;
    }
    .chat-pic {
        border-radius: 50%;
        height: 25px;
        width: 25px;
        padding: 2px 7px;
        margin: 5px;
        .fa-user-online {
            font-size: small;
            color: #FFF;
        }
        .fa-user-offline {
            font-size: small;
            color: #FFF;
        }
    }
    .fa-user-right-pad {
        padding-left: 0;
        padding-right: 5px;
    }
    .chat-text-right-pad {
        padding-left: 5px;
        padding-right: 5px;
    }
    .chat-text {
        background-color: #d1d8f5;
        padding: 5px;
        border-radius: 5px;
        width: fit-content;
        margin: 1px;
    }
    .chat-text-box {
        padding: 15px;
        border-radius: 15px;
    }
    .form-control-icon {
        font-size: 1.5rem;
        right: .5rem;
        top: 0.2rem;
        color: #a9a8a8;
        pointer-events: none;
    }
    span {
        display: block;
        font-size: 15px;
        padding: 4px;
    }
    small {
        display: block;
        font-size: 11px;
    }
    .date {
        margin: 12px 0px;
    }
}

// .progressbar{
//     @include media-breakpoint-down(sm) {
//         height: 100px;
//         .progressbar_child{
//                 margin-top: -40% !important;
//                 font-size: x-small !important;
//         }
//       }
// }